/**
 *  项目的配置文件
 */
export const chatUrl = 'https://im.epcu.cn'
export const mUrl = 'https://m.epcu.cn/'//移动端网页地址
export const apiUrl = 'https://www.epcu.cn/';//接口请求地址
export const curLang = 'zh';//当前语言,zh:中文，若为其他语言，需要对应/src/assets/language下面的文件名
export const title = 'slodon';//浏览器顶部title
export const gdKey = '';//高德web-js key
export const statShowDebug = false;//是否开启统计的调试

/** copyright *** slodon *** version-v4.1 *** date-2022-07-22 ***主版本v4.1**/



